import React from "react"
import { Helmet } from "react-helmet"

import Layout from "../components/layout"
import Container from '../components/container'

export default () => (
  <Layout>
    <Helmet>
      <meta charSet="utf-8" />
      <meta name="description" content="Somos uma empresa plural.{''}
        Juntamos todas as frentes, arquitetura, design de interiores, móveis,{' '}
        incorporações, feiras e eventos para entregar soluções completas para espaços diversos."
      />
      <meta property="og:description" content="Somos uma empresa plural.{''}
        Juntamos todas as frentes, arquitetura, design de interiores, móveis,{' '}
        incorporações, feiras e eventos para entregar soluções completas para espaços diversos."
      />
      <meta name="image" content='/img/voga1.jpg' />
      <meta property="og:image" content='/img/voga1.jpg' />
      <title>Contato Enviado - Grupo Vinte</title>
      <link rel="canonical" href="https://grupovinte.com.br/sucesso/" />
      <html lang="pt-br" />
    </Helmet>
    <main style={{ minHeight: '72vh' }}>
      <Container>
        <h1>Recebemos o seu contato!</h1>
        <p>Em breve enviaremos uma resposta.</p>
      </Container>
    </main>
  </Layout>
)
